import setKioskGraphqlMutation from '~layers/app/graphql/cart/setKiosk.gql'

const stores: { value: string, label: string }[] = [
  { value: '', label: 'Select store' },
  { value: 'groningen', label: 'Groningen' },
  { value: 'zwolle', label: 'Zwolle' },
  { value: 'deventer', label: 'Deventer' },
  { value: 'hardenberg', label: 'Hardenberg' },
  { value: 'almelo', label: 'Almelo' },
  { value: 'vakantiebeurs', label: 'Vakantiebeurs' },
]

const watchersEnabled = ref<boolean>(false)

export default () => {
  const { getCartId } = useCart()

  const kiosk = useCookie('kiosk', {
    maxAge: 60 * 60 * 24 * 60,
    watch: true,
  })

  const label = computed<string>(() =>
    stores.find(({ value }: { value: string }) => value === kiosk.value)?.label || '',
  )

  const {
    mutate: setKioskMutation,
    error: setKioskError,
  } = useMutation(
    setKioskGraphqlMutation,
    { clientId: 'customer' },
  )

  const setKiosk = async (selectedValue: string): Promise<boolean | null> => {
    kiosk.value = selectedValue
    refreshCookie('kiosk')

    if (getCartId.value) {
      await setKioskMutation({ cartId: getCartId.value, kiosk: kiosk.value })
      return !setKioskError.value
    }
    return null
  }

  if (!watchersEnabled.value) {
    watchersEnabled.value = true
    watch(getCartId, (newVal, oldVal) => {
      if (kiosk.value && !oldVal && newVal) {
        setKioskMutation({ cartId: getCartId.value, kiosk: kiosk.value })
      }
    })
  }

  return {
    stores,
    label,
    kiosk,
    setKiosk,
  }
}
